<template>
  <div class="home">
    <!-- <div class="top"> -->
      <VanIcon @click="black" class="arrow" name="arrow-left" />
      <VanTabs class="top_title"  v-model="active">
          <VanTab title="会员特享" class="vip_privileges">
            <VanTabs animated class="top_nav">
              <VanTab  v-for="(item,index) in tabDatas" :key="index" >
                  <div slot="title">  
                  <i :class="`img${index+1}`"></i>{{item.name}}
                  </div>
                  <div class="cont_tab" v-for="(it,id) in item.data" :key="id">
                      <div class="h1">
                        <img :src="it.dataimg">
                        {{it.title}}
                      </div>
                      <div class="h3">
                        {{it.title}}
                      </div>
                      <div class="p1">{{it.content1}}</div>
                      <div class="h3">
                        {{it.title2}}
                      </div>
                      <div class="p1">{{it.content2}}</div>
                      <div class="post_list" :class="`img_list${index+1}`">
                        <img class="img" v-for="(im,ix) in it.postList" :key="ix" :src="im.img1">
                      </div>
                  </div>
                  <span class="counter">{{index+1}}/{{tabDatas.length}}</span>
              </VanTab>
            </VanTabs>
            
          </VanTab>
          <VanTab title="常见问题" class="problem">
              <VanTabs animated >
                  <VanTab v-for="(item1,index1) in problemList" :key="index1">
                    <div slot="title"> {{item1.term_name}}</div>
                    <div class="cont_tab">
                       <div class="h1">
                        {{item1.term_name}}
                      </div>
                      <div v-for="(it1,id1) in item1.post_list" :key="id1">
                        <div class="h3">
                          {{it1.post_title}}
                        </div>
                       <div class="p1" v-html="it1.post_content"></div>
                      </div>
                    </div>
                  </VanTab>
              </VanTabs>
          </VanTab>
      </VanTabs>
 
  </div>
</template>

<script>
import {
  Empty as VanEmpty,
  Icon as VanIcon,
  List as VanList,
  Overlay as VanOverlay,
  Popup as VanPopup,
  Tab as VanTab,
  Tabs as VanTabs,
  Toast,
} from 'vant';
import http from "@/http/request"

export default {
  name: 'Home',
  components: {
    VanTabs,
    VanTab,
    VanEmpty,
    VanIcon,
    VanOverlay,
    VanList,
    VanPopup,
  },
  data() {
    return {
      active: 0,
      tabDatas:[
        {
          name: "无限观影",
          // img: require('@/assets/images/post1.png'),  //动态图片放到public里面的
          data:[
            {
              dataimg:require('@/assets/images/title1.png'),
              title:"无限观影",
              title1:"服务用户",
              content1:"月度会员、季度会员、年度会员、永久会员",
              title2:"权益说明",
              content2:"开通会员VIP即享有无限观影权限，少女、偷窥、时间、性奴、淫妻、网红、乱伦、颜值骚货，海量独播会员影片任你选。",
              postList:[
                {
                  img1:require('@/assets/images/img1.png')
                },
                {
                  img1:require('@/assets/images/img2.png')
                },
                {
                  img1:require('@/assets/images/img3.png')
                },
              ]
            }
          ]
        },
        {
          name: "尊贵身份",
          data:[
            {
              dataimg:require('@/assets/images/title2.png'),
              title:"尊贵身份",
              title1:"服务用户",
              content1:"月度会员、季度会员、年度会员、永久会员",
              title2:"权益说明",
              content2:"开通vip会员即享有vip身份标识，彰显您的尊贵身份。",
              postList:[
                {
                  img1:require('@/assets/images/avatar.png')
                },
                {
                  img1:require('@/assets/images/vip_avatar.png')
                },
              ]
            }
          ]
        },
        {
          name: "服务特权",
          data:[
            {
              dataimg:require('@/assets/images/title3.png'),
              title:"服务特权",
              title1:"服务用户",
              content1:"月度会员、季度会员、年度会员、永久会员",
              title2:"权益说明",
              content2:"如有疑问可进入vip会员页面，进入“联系客服”，专属人工客服在线为您解答，服务更便捷。",
              postList:[
                {
                  img1:require('@/assets/images/banner1.png')
                },
              ]
            }
          ]
        }, 
        {
          name: "广告特权",
          data:[
            {
              dataimg:require('@/assets/images/title4.png'),
              title:"广告特权",
              title1:"服务用户",
              content1:"月度会员、季度会员、年度会员、永久会员",
              title2:"权益说明",
              content2:"开通vip会员，在观看影片时可手动关闭/跳过广告（不包含启动页及公告），屏幕整洁无遮挡。",
              postList:[
                {
                  img1:require('@/assets/images/use.png')
                },
                {
                  img1:require('@/assets/images/vip_use.png')
                },
              ]
            }
          ]
        },
        {
          name: "评论特权",
          data:[
            {
              dataimg:require('@/assets/images/title5.png'),
              title:"评论特权",
              title1:"服务用户",
              content1:"月度会员、季度会员、年度会员、永久会员",
              title2:"权益说明",
              content2:"在观影时可对您喜爱的影片发表评论互动；会员专属评论弹幕，个性又显眼，让您随时随地尽显最贵身份；",
              postList:[
                {
                  img1:require('@/assets/images/banner2.png')
                },
              ]
            }
          ]
        },
        {
          name: "缓存特权",
          data:[
            {
              dataimg:require('@/assets/images/title6.png'),
              title:"缓存特权",
              title1:"服务用户",
              content1:"月度会员、季度会员、年度会员、永久会员",
              title2:"权益说明",
              content2:"Vip会员独享缓存，下载喜爱的影片，随时随地想看就看！",
              postList:[
                {
                  img1:require('@/assets/images/banner3.png')
                },
              ]
            }
          ]
        },
        {
          name: "其他特权",
          img: '4.png',
          data:[
            {
              dataimg:require('@/assets/images/title7.png'),
              title:"其他特权",
              title1:"服务用户",
              content1:"月度会员、季度会员、年度会员、永久会员",
              title2:"权益说明",
              content2:"昵称、个人头像、签名修改权限",
               postList:[
                {
                  img1:require('@/assets/images/banner4.png')
                },
              ]
            }
          ]
        }
      ],
      problemList:[]
    };
  },
  created() {
     let appid = this.GetQueryString(appid)

    let _this = this
    Toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });

    http.all([
      http.post("/v1/message/frontCommQuestion ", appid),
    ]).then(http.spread(function(problem,) {
      Toast.clear();

      if (problem.code == 0) {
        _this.problemList = problem.info.list
      } else {
        Toast(problem.msg)
      }
    })).catch((err) => {
      Toast.clear();
      Toast("获取数据失败！")
      console.log('FAIL', err)
    });
  },
  computed: {

  },
  methods: {
    GetQueryString(name){
      let reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
      let r = window.location.search.substr(1).match(reg);
      if(r!=null)return  unescape(r[2]); return null;
    },
    black(){
      AndroidClient.backToHome()
    },
  },
  mounted() {
  }
}
</script>
<style lang="scss">
body{
  background: #1A1B20;
}
.home {
  background: #1A1B20;
  // background: linear-gradient(180deg, #3F9AF6 0%, rgba(58,36,87,0.54) 10%, rgba(18,15,36,0.30) 12.5%, rgba(35,30,50,0.00) 24.9%,#1A1B20 100%);
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
  .arrow{
      color: #fff;
      padding-left: 20px;
      margin-top: 1px;
      position: absolute;
      top: 25px;
      width: 25px;
      height: 25px;
      z-index: 999;
    }
  // .top{
  //   background: linear-gradient(180deg, rgba(63,154,246,0.3) 0%, rgba(58,36,87,0.54) 60%, rgba(18,15,36,0.30) 80%, rgba(35,30,50,0.00) 100%);
    // height: 200px;  
    .top_title{
      padding-top: 10px;
      background:url(../assets/images/bg.png) no-repeat;
      .van-tabs__wrap:nth-child(1){
        .van-tab{
          justify-content: flex-end;
          
        }
        .van-tab:nth-child(2){
          justify-content: flex-start;
          padding-left: 30px;
        }
      }
    }
      .van-tabs__nav{
        background: none;
        margin-bottom: 0px;
        padding-left: 0;
        padding-bottom: 5px;
        .van-tab{
          font-size: 18px;
          color:#C2C2C2;
          padding: 0;
          div{
            display: flex;
            width:75px;
            flex-wrap:wrap;
            justify-content: center;
            font-size: 14px;
          }
      }
      .van-tab--active{
        color: #FBCEAE;
        div::after{
          height: 3px;
          width: 26px;
          display: block;
          content: "";
          background: #EEC09F;
          border-radius: 13px;
          margin-top: 5px;
          position: absolute;
          bottom: -5px;
        }
        .img1{
          background: url(../assets/images/post1_active.png) no-repeat 0 0/100% 100%;
        }
        .img2{
          background: url(../assets/images/post2_active.png) no-repeat 0 0/100% 100%;
        }
        .img3{
          background: url(../assets/images/post3_active.png) no-repeat 0 0/100% 100%;
        }
        .img4{
          background: url(../assets/images/post4_active.png) no-repeat 0 0/100% 100%;
        }
        .img5{
          background: url(../assets/images/post5_active.png) no-repeat 0 0/100% 100%;
        }
        .img6{
          background: url(../assets/images/post6_active.png) no-repeat 0 0/100% 100%;
        }
        .img7{
          background: url(../assets/images/post7_active.png) no-repeat 0 0/100% 100%;
        }
      }
      .van-tabs__line{
        display: none;
      }
      }
      // }
     .top_nav{
       padding-top: 5px;
      .van-tabs__wrap {
        height: auto;
      }
      i{
        width: 50px;
        height: 50px;
        display: block;
        margin: 0 auto 10px auto;
        // margin-bottom: 10px;
      }
      .van-tab{
        padding-left:0px !important;
        justify-content:center;
      }
    }
    .img1{
      background: url(../assets/images/post1.png) no-repeat 0 0/100% 100%;
    }
    .img2{
      background: url(../assets/images/post2.png) no-repeat 0 0/100% 100%;
    }
    .img3{
      background: url(../assets/images/post3.png) no-repeat 0 0/100% 100%;
    }
    .img4{
      background: url(../assets/images/post4.png) no-repeat 0 0/100% 100%;
    }
    .img5{
      background: url(../assets/images/post5.png) no-repeat 0 0/100% 100%;
    }
    .img6{
      background: url(../assets/images/post6.png) no-repeat 0 0/100% 100%;
    }
    .img7{
      background: url(../assets/images/post7.png) no-repeat 0 0/100% 100%;
    }
    
  }
  .cont_tab{
    // background: linear-gradient(180deg, #332B58 0%, rgba(45,38,80,0.00) 100%);
    background: url(../assets/images/cont_bg.png) no-repeat 0 0/100% 100%;
    border-radius: 13px;
    width: 88%;
    height:420px ;
    margin: 10px auto;
    padding:20px 0 20px 0;
    overflow: hidden;
    overflow-y: auto;
    .h1{
      font-size: 18px;
      color: #FFFFFF;
      display: flex;
      line-height: 50px;
      img{
        width: 50px;
        height: 50px;
        display: block;
        padding: 0 10px;
      }
    }
    .h3{
      font-size: 14px;
      color: #FFFFFF;
      padding-left: 10px;
      padding-top: 15px;
      display: flex;
    }
    .h3::before{
       content: "";
       height: 14px;
       width: 3px;
       display: block;
       background: linear-gradient(180deg, #A4138C 0%, #354CB4 100%);
       border-radius: 13px;
       margin-right: 8px;
       margin-top: 3px;
    }
    .p1{
      font-size: 13px;
      color: #A7A7A7;
      padding: 10px 10px 0 12px;
    }
    .post_list{
      display: flex;
      justify-content:center;
      margin-top: 32px;
      img{
        width: 19rem;
        height: 135px;
      }
    }
    .img_list1{
      img{
        width:100px;
        height:145px;
        padding-left: 5px;
      }
      img:nth-of-type(1){
        padding: 0;
      }
    }
    .img_list2{
      justify-content:center;
      img{
        width: 45%;
        height: 100%;
      }
      img:nth-of-type(2){
        margin-left: 5px;
      }
    }
    .img_list4{
      justify-content:space-around;
      margin-top:15px ;
        img{
        width: 108px;
        height: 218px;
      }
    }
  }
  .counter{
    display: block;
    text-align: center;
    font-size: 15px;
    color: #D6AC93;
  }
// }
.problem{
  .van-tab{
      padding-left: 0px !important;
      justify-content: center !important;
  }
   
  .van-tabs__nav{
        background: none;
        margin-bottom: 10px;
        .van-tab{
          font-size: 14px;
          color: #CDCDCD;
          div{
             display: flex;
             flex-wrap:wrap;
             justify-content: center;
             width: 85px;
          }
        }
        // .van-tab:nth-of-type(1){
        //   div{
        //      width: 90px;
        //   }
        // }
  }
   .van-tab--active{
        div{
          color: #FBCEAE;
        }
  }
  .h1{
    font-size: 18px;
    color: #FFFFFF;
    padding-left: 16px;
  }
}
</style>
